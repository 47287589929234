define("frontend/services/authentication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    id: null,
    userId: null,
    user: null,
    role: Ember.computed.readOnly('user.role'),
    impersonatingUserId: null,
    impersonatingUser: null,
    impersonatingUserRole: Ember.computed.readOnly('impersonatingUser.role'),
    transitionAfterLogin: null,
    store: Ember.inject.service(),
    activeUserId: Ember.computed('userId', 'impersonatingUserId', function () {
      return this.impersonatingUserId || this.userId;
    }),
    activeRole: Ember.computed('role', 'impersonatingUserRole', function () {
      return this.impersonatingUserRole || this.role;
    }),
    activeUser: Ember.computed('user', 'impersonatingUser', function () {
      return this.impersonatingUser || this.user;
    }),
    isAdmin: Ember.computed.equal('activeRole', 'admin'),
    isAgent: Ember.computed.equal('activeRole', 'agent'),
    isClientUser: Ember.computed.equal('activeRole', 'client'),
    isClientManager: Ember.computed.equal('activeRole', 'client_manager'),
    isTeamManager: Ember.computed.equal('activeRole', 'team_manager'),
    isClient: Ember.computed.or('isClientUser', 'isClientManager', 'isTeamManager'),
    canImpersonate: Ember.computed('isAdmin', 'isClientManager', 'isTeamManager', function () {
      return this.isAdmin || this.isClientManager || this.isTeamManager;
    }),
    isImpersonatingUser: Ember.computed.bool('impersonatingUserId'),
    canHearClientRecordings: Ember.computed.or('isAdmin', 'isClient'),
    canHearAgentRecordings: Ember.computed.or('isAdmin', 'isAgent'),
    init: function init() {
      this._super.apply(this, arguments);

      this.getProperties('userId', 'impersonatingUserId', 'isImpersonatingUser', 'isAdmin', 'isAgent', 'isClient', 'isClientManager', 'isTeamManager');
      this.readFromLocalStorage();
    },
    login: function login(session) {
      this.clear();
      this.setProperties(session.getProperties('id', 'userId'));
      this.saveToLocalStorage();
      this.trigger('loggedIn');
      return this._updateUser();
    },
    loggedIn: Ember.computed.bool('id'),
    clear: function clear() {
      this.setProperties({
        id: null,
        userId: null,
        user: null,
        impersonatingUserId: null,
        impersonatingUser: null,
        errors: null
      });
      return this.saveToLocalStorage();
    },
    logout: function logout() {
      var _this = this;

      if (!this.loggedIn) {
        return;
      }

      var store = this.store;
      store.pushPayload({
        session: {
          id: 'current'
        }
      });
      var session = store.peekRecord('session', 'current');
      return session.destroyRecord().then(function () {
        _this.clear();

        _this.trigger('loggedOut'); // do a hard reload to reset the data and redirect back to login


        return window.location.href = '/';
      });
    },
    initializeUser: function initializeUser() {
      return Ember.RSVP.all([this._updateUser(), this._updateImpersonatingUser()]);
    },
    _updateUser: function _updateUser() {
      var _this2 = this;

      var userId = this.userId;

      if (userId) {
        // query the server to get the user associated with the specified session
        var store = this.store;
        return store.find('user', userId).then(function (user) {
          _this2.set('user', user);
        });
      } else {
        this.set('user', null);
      }
    },
    impersonate: function impersonate(userId) {
      var _this3 = this;

      var adapter = this.store.adapterFor('application');
      return adapter.executeServerAction('session', null, 'impersonate', {
        data: {
          user_id: userId
        }
      }).then(function (result) {
        var u = result.impersonating_user || {};

        _this3.set('impersonatingUserId', u.id);

        _this3.saveToLocalStorage(); // do a hard reload to reset the data and redirect back to login


        return window.location.href = '/';
      }).catch(function () {});
    },
    _updateImpersonatingUser: function _updateImpersonatingUser() {
      var _this4 = this;

      var impersonatingUserId = this.impersonatingUserId;

      if (impersonatingUserId) {
        return this.store.find('user', impersonatingUserId).then(function (model) {
          return _this4.set('impersonatingUser', model);
        }).catch(function () {});
      } else {
        return this.set('impersonatingUser', null);
      }
    },
    localStorageProperties: ['id', 'userId', 'impersonatingUserId'],
    requiredProperties: ['id', 'userId'],
    readFromLocalStorage: function readFromLocalStorage() {
      var properties = {};

      for (var _i = 0, _Array$from = Array.from(this.localStorageProperties); _i < _Array$from.length; _i++) {
        var p = _Array$from[_i];

        /* 
          if the session key is missing, then the user is not logged in properly 
          and we should log them out to clear the session
        */
        if (this.requiredProperties.includes(p)) {
          if (!localStorage["session.".concat(p)]) {
            return this.logout();
          }
        }

        properties[p] = localStorage["session.".concat(p)];
      }

      return this.setProperties(properties);
    },
    saveToLocalStorage: function saveToLocalStorage() {
      var _this5 = this;

      return Array.from(this.localStorageProperties).map(function (p) {
        return _this5._savePropertyToLocalStorage("session.".concat(p), _this5.get(p));
      });
    },
    _savePropertyToLocalStorage: function _savePropertyToLocalStorage(name, value) {
      if (value) {
        return localStorage[name] = value;
      } else {
        return delete localStorage[name];
      }
    }
  });

  _exports.default = _default;
});